import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/blog-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Week 6`}</h1>
    <p>{`I’ve been looking forward to this week of new releases for a while. It covers 5 new releases from some exciting artists. Nicole released her debut EP, Pariah is back with a deep techno track, Beatrice Dillon covers all aspects of rhythm, Perko released his second EP on Numbers and Nicolas Jaar is back on his Against All Logic alias.`}<div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=2106952941/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless>
  <a href="http://eerie-records.bandcamp.com/album/inside">Inside by Nicole</a>
    </iframe>
    <h1>{`Nicole - Inside`}</h1>
    <p>{`Inside is the debut EP of the 22 year old Nicole from Batumi. It consists 4 tracks that blend Detroit and IDM in a modern way. I’ve had the first track on a repeat way too much this week, and it doesn’t get boring yet!`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://eerie-records.bandcamp.com/album/inside"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe width="180" height="180" src="https://www.youtube.com/embed/chVKBxRP7_k" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h1>{`Nathan Melja - Synesthesia (Pariah Remix)`}</h1>
    <p>{`Karenn half Pariah delivers a deep techno track. Epic buildups and multi-level textural innovation.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://kalaharioystercult.bandcamp.com/merch/nathan-melja-synesthesia-12-w-anthony-naples-pariah-remixes-oyster21"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=2209323727/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless>
  <a href="http://beatricedillon.bandcamp.com/album/workaround">
    Workaround by Beatrice Dillon
  </a>
    </iframe>
    <h1>{`Beatrice Dillon - Workaround`}</h1>
    <p>{`Solo debut album by Beatrice Dillon on PAN. It’s a lucidly playful and ambitious solo and covers all the aspects of rhythm.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://beatricedillon.bandcamp.com/album/workaround"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=3120240092/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless>
  <a href="http://perko.bandcamp.com/album/the-city-rings">
    The City Rings by Perko
  </a>
    </iframe>
    <h1>{`Perko - The City Rings`}</h1>
    <p>{`I’ve been following Perko for a while since his first EP on Numbers. He’s back with his second EP with one of my favourite tracks of the past month ’Stutter’.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://perko.bandcamp.com/album/the-city-rings"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=2872755879/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless>
  <a href="http://against-all-logic.bandcamp.com/album/2017-2019">
    2017 - 2019 by Against All Logic
  </a>
    </iframe>
    <h1>{`Against All Logic - 2017-2019`}</h1>
    <p>{`Nicolas Jaar is back on his AAL alias. It’s a club-focused LP with hard-hitting and seriously over-driven sounds.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "http://against-all-logic.bandcamp.com/album/2017-2019"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      